import styled from 'styled-components';
import PictureBanner from '../../../components/PictureBanner';
import portfolio_banner from '../../../assets/images/portfolioPage/portfolio_banner.png';
import TopBar from '../../../components/user/topBar/TopBar';
import FloatingButtonGroup from '../../../components/user/mainPage/FloatingButton';
import portfolio_background from '../../../assets/images/portfolioPage/portfolio_background.svg';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import PortfolioService, {
  PortfolioData,
} from '../../../api/service/user/PortfolioService';
import CategoryService, {
  CategoryData,
} from '../../../api/service/user/CategoryService';

const PortfolioContainer = styled.div`
  width: 100%;
  background-image: url(${portfolio_background});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 0 400px;
  padding: 0 15%;
  max-width: 1920px;
  margin: 0 auto;
  margin-bottom: 128px;

  @media (max-width: 1024px) {
    padding: 0 10%;
  }
  @media (max-width: 1024px) {
    padding: 0 5%;
    background-image: none;
    margin-bottom: 64px;
  }
`;
const Title = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 20px;
  padding: 50px 0px 0px;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 20px 0px 0px;
  }
`;

const CompanyName = styled.p`
  font-size: 30px;
  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const SiteName = styled.h1`
  font-size: 50px;
  @media (max-width: 768px) {
    font-size: 30px;
  }
`;

const Category = styled.div`
  display: flex;
  gap: 10px;
  padding: 20px 0px 0px;
`;

const CategoryName = styled.p`
  font-size: 20px;
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const ImgContainer = styled.div`
  margin-top: 20px;
  width: 100%;
`;

const Img = styled.img`
  width: 100%;
`;

function PortfolioDetailPage() {
  const location = useLocation();
  const idPortfolio = location.state?.id; // `state`에서 ID 가져오기
  const [portfolio, setPortfolio] = useState<PortfolioData | null>(null);
  const [categories, setCategories] = useState<CategoryData[]>([]);
  const [categoryName, setCategoryName] = useState<string | null>(null);
  const [subCategories, setSubCategories] = useState<CategoryData[]>([]);
  const [subCategoryName, setSubCategoryName] = useState<string | null>(null);
  const [imageSrc, setImageSrc] = useState<string | null>(null);

  useEffect(() => {
    if (!idPortfolio) return;
    fetchCategories();
    fetchPortfolioDetails();
  }, [idPortfolio]);

  const fetchPortfolioDetails = async () => {
    try {
      const response = await PortfolioService.getPortfolioDetails({
        idPortfolio,
      });
      setPortfolio(response.data);
      fetchSubCategories(response.data.category.categoryId);

      // resizedUrl을 먼저 설정
      if (response.data.detailedImage?.resizedUrl) {
        setImageSrc(response.data.detailedImage.resizedUrl);
      }
    } catch (err) {
      console.error('포트폴리오 데이터를 불러오는데 실패했습니다:', err);
    }
  };

  const fetchCategories = async () => {
    try {
      const rootCategories = await CategoryService.getRootCategories(); // 1차 카테고리 가져오기
      setCategories(rootCategories.data);
    } catch (error) {
      console.error('카테고리 데이터를 불러오는데 실패했습니다:', error);
    }
  };

  const fetchSubCategories = async (parentId: number) => {
    try {
      const rootCategories = await CategoryService.getSubCategories(parentId); // 1차 카테고리 가져오기
      setSubCategories(rootCategories.data);
    } catch (error) {
      console.error('카테고리 데이터를 불러오는데 실패했습니다:', error);
    }
  };

  // 카테고리 ID를 한글 이름으로 변환하는 함수
  useEffect(() => {
    if (!portfolio?.category?.categoryId || categories.length === 0) return;

    const matchedCategory = categories.find(
      (cat) => cat.id === portfolio.category.categoryId
    );
    const matchedSubCategory = subCategories.find(
      (cat) => cat.id === portfolio.category.subCategoryId
    );
    if (matchedCategory) {
      setCategoryName(matchedCategory.mainCategory); // 한글 이름 설정
    }
    if (matchedSubCategory) {
      setSubCategoryName(matchedSubCategory.subCategory); // 한글 이름 설정
    }
  }, [portfolio, categories, subCategories]);

  return (
    <>
      <FloatingButtonGroup />
      <TopBar variant="dark" />
      <PictureBanner
        title="PORTFOLIO"
        subtitle={
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              gap: '1rem',
            }}
          >
            <span
              style={{
                background: '#26262680',
                padding: '0.2rem',
                fontSize: '0.9rem',
              }}
            >
              #개발
            </span>
            <span
              style={{
                background: '#26262680',
                padding: '0.2rem',
                fontSize: '0.9rem',
              }}
            >
              #디자인
            </span>
            <span
              style={{
                background: '#26262680',
                padding: '0.2rem',
                fontSize: '0.9rem',
              }}
            >
              #하드웨어
            </span>
          </div>
        }
        titleColor="white"
        imagePath={portfolio_banner}
      />
      <PortfolioContainer>
        <Title>
          <CompanyName>{portfolio?.companyName}</CompanyName>
          <SiteName>{portfolio?.siteName}</SiteName>
        </Title>
        <Category>
          <CategoryName>
            {categoryName || '카테고리 없음'}
            {' / '}
            {subCategoryName || '카테고리 없음'}
          </CategoryName>
        </Category>
        <ImgContainer>
          {imageSrc && (
            <Img
              src={imageSrc}
              alt="포트폴리오 이미지"
              onLoad={() => {
                if (portfolio?.detailedImage?.originalUrl) {
                  setImageSrc(portfolio.detailedImage.originalUrl);
                }
              }}
            />
          )}
        </ImgContainer>
      </PortfolioContainer>
    </>
  );
}

export default PortfolioDetailPage;
