import React from 'react';
import styled from 'styled-components';
import plus from '../../../assets/images/mainPage/plus.svg';

interface KeyTechnologyCardProps {
  title: string;
  description: string;
  iconPath: string; // 변경: iconSrc → iconPath
}

const Card = styled.div`
  border-radius: 10px;
  border: 1px solid #2626261a;
  padding: 24px;
  position: relative;
  background-color: #fff;

  &:hover .overlay {
    opacity: 1;
  }
`;

const Overlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #262626e5;
  border-radius: 10px;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  transition: opacity 0.5s;
`;

const WhiteButton = styled.button`
  margin-bottom: 15%;
  margin: 0 auto;
  background: white;
  border: none;
  border-radius: 5px;
  padding: 8px 16px;
  font-size: 0.9rem;
  cursor: pointer;
`;

const Title = styled.p`
  color: #262626;
  font-weight: bold;
  font-size: 1.25rem;
  margin-bottom: 12px;
`;

const Description = styled.p`
  color: #262626b2;
  font-size: 16px;
  word-break: keep-all;
  line-height: 20px;
`;

const Footer = styled.div`
  display: flex;
  align-items: flex-end;
`;

const FooterText = styled.div`
  display: flex;
  align-items: center;
  color: #c8c8c8;
  font-size: small;
  span {
    margin-right: 8px;
  }
`;

const Icon = styled.img`
  margin-left: auto;
`;

const KeyTechnologyCard: React.FC<KeyTechnologyCardProps> = ({
  title,
  description,
  iconPath,
}) => {
  return (
    <Card>
      <Overlay className="overlay">
        <WhiteButton aria-label="자세히 보기">자세히 보기</WhiteButton>
      </Overlay>
      <Title>{title}</Title>
      <Description>{description}</Description>
      <Footer>
        <FooterText>
          <span>자세히 보기</span>
          <img src={plus} alt="plus icon" />
        </FooterText>
        <Icon src={iconPath} alt={`${title} icon`} />
      </Footer>
    </Card>
  );
};

export default KeyTechnologyCard;
