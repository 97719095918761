import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useIsMobile } from '../../../hooks/useIsMobile';
import PictureBanner from '../../../components/PictureBanner';
import portfolio_banner from '../../../assets/images/portfolioPage/portfolio_banner.png';
import TopBar from '../../../components/user/topBar/TopBar';
import FloatingButtonGroup from '../../../components/user/mainPage/FloatingButton';
import decoration from '../../../assets/images/decoration.svg';
import PortfolioPageTab1 from '../../../components/user/portfolioPage/PortfolioPageTab1';
import portfolio_background from '../../../assets/images/portfolioPage/portfolio_background.svg';
import PortfolioPageTab2 from '../../../components/user/portfolioPage/PortfolioPageTab2';
import PortfolioPageTab3 from '../../../components/user/portfolioPage/PortfolioPageTab3';
import CategoryService from '../../../api/service/user/CategoryService';
import { CategoryData } from '../../../api/service/user/CategoryService';

const PortfolioContainer = styled.div`
  background-image: url(${portfolio_background});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 0 400px;
  padding: 0 15%;
  max-width: 1920px;
  margin: 0 auto;
  margin-bottom: 128px;

  @media (max-width: 1440px) {
    padding: 0 10%;
  }
  @media (max-width: 1024px) {
    padding: 0 5%;
    background-image: none;
    margin-bottom: 64px;
  }
`;

const TabGroupContainer = styled.div`
  margin-top: 48px;
  display: flex;
  justify-content: start;
  gap: 1rem;

  @media (max-width: 1024px) {
    justify-content: center;
    margin-top: 12px;
    gap: 0.5rem;
  }
`;

const TabButton = styled.button<{ isActive: boolean }>`
  padding: 8px 0;
  width: 100%;
  background: ${({ isActive }) =>
    isActive ? 'rgba(38, 38, 38, 0.50)' : '#fff'};
  color: ${({ isActive }) => (isActive ? '#fff' : '#262626')};
  text-align: center;
  border: 1px solid rgba(38, 38, 38, 0.2);
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background: ${({ isActive }) =>
      isActive ? 'rgba(38, 38, 38, 0.50)' : '#fff'};
    color: ${({ isActive }) => (isActive ? '#fff' : '#262626')};
  }
`;

const DesktopTab = styled.div<{ isActive: boolean }>`
  padding: 12px 0;
  width: 240px;
  position: relative;
  overflow: hidden;
  background: ${({ isActive }) => (isActive ? 'black' : '#C9C9C933')};
  text-align: center;
  color: white;
  font-size: 20px;
  cursor: pointer;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;

  img {
    position: absolute;
    width: 40%;
  }

  img:first-of-type {
    right: -15%;
    max-width: 1920px;
    margin: 0 auto;
    top: 0;
  }

  img:last-of-type {
    left: 0;
    bottom: 0;
    transform: scaleY(-1);
  }
`;

type TabsProps = {
  tabTitles: string[];
  activeTabIndex: number;
  setActiveTabIndex: (index: number) => void;
  isMobile: boolean;
};

const Tabs: React.FC<TabsProps> = ({
  tabTitles,
  activeTabIndex,
  setActiveTabIndex,
  isMobile,
}) => {
  return (
    <TabGroupContainer>
      {tabTitles.map((title, index) =>
        isMobile ? (
          <TabButton
            key={title}
            isActive={activeTabIndex === index}
            onClick={() => setActiveTabIndex(index)}
          >
            {title}
          </TabButton>
        ) : (
          <DesktopTab
            key={title}
            isActive={activeTabIndex === index}
            onClick={() => setActiveTabIndex(index)}
          >
            {title}
            <img src={decoration} alt="" />
            <img src={decoration} alt="" />
          </DesktopTab>
        )
      )}
    </TabGroupContainer>
  );
};

function PortfolioPage() {
  const isMobile = useIsMobile();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [tabTitles, setTabTitles] = useState<string[]>([]); // 초기값
  const [tabIds, setTabIds] = useState<(number | null)[]>([]);

  useEffect(() => {
    fetchCategories();
  }, []);

  // ✅ API에서 카테고리 데이터를 가져와서 `tabTitles` 업데이트
  const fetchCategories = async () => {
    try {
      const response = await CategoryService.getRootCategories();
      if (response) {
        const formattedCategories = response.data.map(
          (category: CategoryData) => category.mainCategory
        );
        const categoryIds = response.data.map(
          (category: CategoryData) => category.id
        );

        // 📌 "전체(ALL)" 옵션 추가
        const updatedCategories = [...formattedCategories];

        setTabTitles(updatedCategories); // 📌 탭 타이틀 업데이트
        setTabIds([...categoryIds]); // ✅ 탭 ID 값도 업데이트 (전체는 null)
        setActiveTabIndex(0); // 기본 선택값 설정
      }
    } catch (error) {
      console.error('카테고리 로드 실패:', error);
    }
  };

  return (
    <>
      <FloatingButtonGroup />
      <TopBar variant="dark" />
      <PictureBanner
        title="PORTFOLIO"
        subtitle={
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              gap: '1rem',
            }}
          >
            <span
              style={{
                background: '#26262680',
                padding: '0.2rem',
                fontSize: '0.9rem',
              }}
            >
              #개발
            </span>
            <span
              style={{
                background: '#26262680',
                padding: '0.2rem',
                fontSize: '0.9rem',
              }}
            >
              #디자인
            </span>
            <span
              style={{
                background: '#26262680',
                padding: '0.2rem',
                fontSize: '0.9rem',
              }}
            >
              #하드웨어
            </span>
          </div>
        }
        titleColor="white"
        imagePath={portfolio_banner}
      />
      <PortfolioContainer>
        <Tabs
          tabTitles={tabTitles}
          activeTabIndex={activeTabIndex}
          setActiveTabIndex={setActiveTabIndex}
          isMobile={isMobile}
        />

        {/* 선택된 탭에 따라 다른 콘텐츠 렌더링 */}
        <div>
          {activeTabIndex === 0 && (
            <PortfolioPageTab1 categoryId={tabIds[activeTabIndex]} />
          )}
          {activeTabIndex === 1 && (
            <PortfolioPageTab2 categoryId={tabIds[activeTabIndex]} />
          )}
          {activeTabIndex === 2 && (
            <PortfolioPageTab3 categoryId={tabIds[activeTabIndex]} />
          )}
        </div>
      </PortfolioContainer>
    </>
  );
}

export default PortfolioPage;
