import styled from 'styled-components';
import KeyTechnologyCard from './KeyTechnologyCard';
import CDN from '../../../assets/images/mainPage/CDN.svg';
import LMS from '../../../assets/images/mainPage/LMS.svg';
import SI from '../../../assets/images/mainPage/SI.svg';
import hosting from '../../../assets/images/mainPage/hosting.svg';
import video from '../../../assets/images/mainPage/video.svg';

const Screen = styled.div`
  display: flex;
  flex-direction: column;
  height: 100dvh;
  padding: 0px 15%;
  max-width: 1920px;
  margin: 0 auto;

  @media (max-width: 1440px) {
    padding: 0px 10%;
  }
  @media (max-width: 1024px) {
    padding: 0px 5%;
    height: auto;
  }
`;

const ScreenPadding = styled.div`
  height: auto;
  width: auto;
  margin-top: 120px;
  margin-bottom: 120px;
  @media (max-width: 1024px) {
    margin-top: 25px;
    margin-bottom: 50px;
  }
`;

const Title = styled.h1`
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.96px;
  @media (max-width: 1024px) {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.48px;
  }
`;

const SubTitle = styled.h1`
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  @media (max-width: 1024px) {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

const LodongSystemText = styled.p`
  color: #26262605;
  font-size: 120px;
  font-weight: 900;
  margin-bottom: -24px;
  text-align: center;

  @media (max-width: 1024px) {
    font-size: 32px;
    margin-bottom: -6px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  @media (max-width: 1024px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

function KeyTechnology() {
  return (
    <Screen>
      <ScreenPadding>
        <Title style={{}}>KEY TECHNOLOGY</Title>
        <SubTitle>로동만의 기술적인 시스템</SubTitle>
        <LodongSystemText>LODONG SYSTEM</LodongSystemText>
        <Grid>
          <KeyTechnologyCard
            title="비디오 스트리밍 서비스"
            description="사용자의 영상 콘텐츠 관리 및 컨텐츠 구독 신청"
            iconPath={video}
          />
          <KeyTechnologyCard
            title="SI개발"
            description="여러 소프트웨어와 하드웨어를 하나의
시스템으로 만드는 기술. 이들이 서로 잘 연결되고 원활하게 작동하도록 지원"
            iconPath={SI}
          />
          <KeyTechnologyCard
            title="호스팅 서비스"
            description="웹사이트 파일을 저장 및 인터넷 연결을 통해 사람들이 언제 어디서든 웹사이트에 원활한 접속을 지원"
            iconPath={hosting}
          />
          <KeyTechnologyCard
            title="비디오 스트리밍 서비스"
            description="사용자의 영상 콘텐츠 관리 및 컨텐츠 구독 신청"
            iconPath={video}
          />
          <KeyTechnologyCard
            title="CDN 서비스"
            description="외국의 웹사이트에 접속하면 가까운 외국 서버에 컨텐츠를 제공하여 빠르게 웹사이트 이용가능"
            iconPath={CDN}
          />
          <KeyTechnologyCard
            title="HRD & LMS"
            description="교육 훈련 과정 및 학습자 관리의 온라인 플랫폼
다양한 교육 프로그램 정보를 제공"
            iconPath={LMS}
          />
        </Grid>
      </ScreenPadding>
    </Screen>
  );
}

export default KeyTechnology;
