import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { PortfolioDetailPageUrl } from '../url/lodongUrl';
import { IoArrowForwardCircleOutline } from 'react-icons/io5';
import ImagePreview from './ImagePreview';
import { PortfolioListData } from '../api/service/user/PortfolioService';

const GridItem = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 1;
  overflow: hidden;
  text-align: center;
  background-color: #f9f9f9;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: translateY(-10px); /* 살짝 들어올림 */
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const OverlayTitle = styled.h2`
  word-break: keep-all;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 34px; /* 87.5% */

  @media (max-width: 1440px) {
    font-size: 24px;
    line-height: 28px; /* 87.5% */
  }
  @media (max-width: 1024px) {
    font-size: 20px;
    line-height: 24px; /* 87.5% */
  }
`;

const OverlayDescription = styled.p`
  text-align: start;
  word-break: keep-all;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 171.429% */

  @media (max-width: 1024px) {
    font-size: 12px;
    line-height: 16px; /* 171.429% */
  }
`;

const TagsBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 3px;

  span {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 200% */
  }

  @media (max-width: 1024px) {
    span {
      font-size: 10px;
      line-height: 14px; /* 200% */
    }
  }
`;
const Line = styled.div`
  width: 100%;
  background-color: #fff;
  height: 1px;
`;
const Icon = styled(IoArrowForwardCircleOutline)`
  cursor: pointer;
  font-size: 24px;
  position: absolute;
  bottom: 20px;
  right: 20px;
`;

// 새로운 효과 스타일 추가
const SlideInOverlay = styled.div`
  position: absolute;
  bottom: -100%;
  left: 0;
  width: 100%;
  height: 100%;
  background: #262626;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  padding: 20px;
  transition: all 0.2s ease-in-out;

  ${GridItem}:hover & {
    bottom: 0; /* 호버 시 텍스트가 위로 슬라이드 */
  }
  @media (max-width: 1024px) {
    gap: 10px;
  }
`;

export default function ProjectSummaryCard({
  portfolio,
}: {
  portfolio: PortfolioListData;
}) {
  const navigate = useNavigate();

  const handleIconClick = (portfolioId: string) => {
    navigate(PortfolioDetailPageUrl, { state: { id: portfolioId } });
  };

  return (
    <GridItem key={portfolio.idPortfolio}>
      <ImageWrapper>
        <ImagePreview
          image={portfolio.baseImageUrlVo}
          alt={portfolio.siteName}
        />
        <SlideInOverlay>
          <OverlayTitle>{portfolio.siteName}</OverlayTitle>
          <OverlayDescription>{portfolio.simpleDescription}</OverlayDescription>
          <Line />
          <TagsBox>
            {portfolio.tags.map((tag, index) => (
              <span key={index}>{tag}</span>
            ))}
          </TagsBox>
          <Icon onClick={() => handleIconClick(portfolio.idPortfolio)} />
        </SlideInOverlay>
      </ImageWrapper>
    </GridItem>
  );
}
