import styled from 'styled-components';
import { useIsMobile } from '../../../hooks/useIsMobile';
import DesktopTopBar from './DesktopTopBar';
import MobileTopBar from './MobileTopBar';
import { useState, useEffect } from 'react';

interface TopBarProps {
  isShowPlaying?: boolean; // 기본값을 지원하기 위해 optional로 설정
  variant?: 'light' | 'dark'; // TopBar의 색상 옵션
}

const StyledStickyTopBar = styled.div<{ $isShowPlaying?: boolean }>`
  display: ${({ $isShowPlaying }) => ($isShowPlaying ? 'none' : 'block')};
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 100;
  transform-origin: top;
`;

const LoadedTopBar = styled.div<{ $isMobile?: boolean }>`
  height: ${({ $isMobile }) => ($isMobile ? '100px' : '60px')};
  display: block;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: #202020;
  transform-origin: top;
`;

function TopBar({ isShowPlaying, variant = 'dark' }: TopBarProps) {
  const [isLoaded, setIsLoaded] = useState(false);
  const isMobile = useIsMobile();

  useEffect(() => {
    // 화면 크기 계산 후 로딩 완료
    setIsLoaded(true);
  }, [isMobile]);

  if (!isLoaded) {
    // 초기 로딩 중에는 아무것도 렌더링하지 않음
    return <LoadedTopBar $isMobile={isMobile} />;
  }

  return (
    <div style={{ backgroundColor: '#202020' }}>
      <StyledStickyTopBar $isShowPlaying={isShowPlaying}>
        {isMobile ? (
          <MobileTopBar variant={variant} />
        ) : (
          <DesktopTopBar variant={variant} />
        )}
      </StyledStickyTopBar>
    </div>
  );
}

export default TopBar;
