import React from 'react';
import styled from 'styled-components';
import close from '../../../assets/images/close.svg';
import { useNavigate } from 'react-router-dom';
import MobileTopBarMenu from './MobileTopBarMenu';
import MobileTopBarSubmenu from './MobileTopBarSubmenu';
import {
  CeoIntroductionPageUrl,
  CompanyIntroductionPageUrl,
  InquiryPageUrl,
  LocationPageUrl,
  PortfolioPageUrl,
  HrdloServicesPageUrl,
} from '../../../url/lodongUrl';

// entries 배열 (메뉴 항목들)
const entries = [
  { name: '포트폴리오', link: PortfolioPageUrl },
  {
    name: '회사 소개',
    subEntries: [
      { name: 'CEO 인사말', link: CeoIntroductionPageUrl },
      { name: '로동 소개', link: CompanyIntroductionPageUrl },
    ],
  },
  { name: '홈페이지 제작', link: HrdloServicesPageUrl },
  { name: '서비스 소개', link: HrdloServicesPageUrl },
  { name: '문의하기', link: InquiryPageUrl },
  { name: '오시는 길', link: LocationPageUrl },
];

interface MobileMenuDrawerProps {
  isOpen: boolean;
  closeMenu: () => void;
}

const Drawer = styled.div<{ $isMenuOpen: boolean }>`
  width: 30vw;
  max-width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  /* $isMenuOpen이 false일 때, 자신의 너비(30vw)를 기준으로 완전히 이동 */
  transform: ${(props) =>
    props.$isMenuOpen ? 'translateX(0)' : 'translateX(100%)'};
  transition: transform 0.5s ease;
  background: white;
  z-index: 101;

  @media (max-width: 1024px) {
    width: 100vw;
    transform: ${(props) =>
      props.$isMenuOpen ? 'translateX(0)' : 'translateX(100%)'};
  }
`;

const CloseIcon = styled.img`
  position: absolute;
  right: 24px;
  top: 24px;
  width: 12px;
  height: 12px;
  cursor: pointer;
`;

const DrawerHeader = styled.div`
  margin: 24px;
  padding-left: 24px;
  font-size: 28px;
  font-weight: bold;
  border-left: 1px solid red;
`;

const RedText = styled.p`
  color: #ff2424;
  margin-bottom: 16px;
`;

const DarkText = styled.p`
  color: #262626;
`;

const MenuContent = styled.div`
  padding: 24px 0;
`;

const MobileMenuDrawer: React.FC<MobileMenuDrawerProps> = ({
  isOpen,
  closeMenu,
}) => {
  const navigate = useNavigate();

  return (
    <Drawer $isMenuOpen={isOpen}>
      <CloseIcon src={close} alt="close" onClick={closeMenu} />
      <DrawerHeader>
        <RedText>NOTHING IS IMPOSSIBLE</RedText>
        <DarkText>실패 없는 개발, 로동</DarkText>
      </DrawerHeader>
      <MenuContent>
        {entries.map((entry, entryIndex) =>
          entry.subEntries ? (
            <MobileTopBarMenu key={`entry-${entryIndex}`} text={entry.name}>
              {entry.subEntries.map((subEntry, subEntryIndex) => (
                <MobileTopBarSubmenu
                  key={`subEntry-${entryIndex}-${subEntryIndex}`}
                  text={subEntry.name}
                  onClick={() => {
                    navigate(subEntry.link);
                    closeMenu();
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                  }}
                />
              ))}
            </MobileTopBarMenu>
          ) : (
            <MobileTopBarMenu
              key={`entry-${entryIndex}`}
              text={entry.name}
              onClick={() => {
                navigate(entry.link);
                closeMenu();
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }}
            />
          )
        )}
      </MenuContent>
    </Drawer>
  );
};

export default MobileMenuDrawer;
