import { useNavigate } from 'react-router-dom';
import logo from '../../../assets/images/logo.png';
import styled from 'styled-components';
import { MainPageUrl } from '../../../url/lodongUrl';

const LogoContainer = styled.div`
  display: flex;
  gap: 10px;
  cursor: pointer;
  align-items: center;
`;

const LogoImage = styled.img`
  width: 100%;
  height: 55px;
  @media (max-width: 1440px) {
    width: 180px;
    height: 50px;
  }
  @media (max-width: 1024px) {
    width: 100%;
    height: 40px;
  }
`;

function Logo() {
  const navigate = useNavigate();
  return (
    <LogoContainer onClick={() => navigate(MainPageUrl)}>
      <LogoImage src={logo} alt="logo" />
    </LogoContainer>
  );
}

export default Logo;
