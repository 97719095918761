// 엔드포인트 URL을 생성하는 함수의 타입을 정의합니다.
export interface PortfolioEndpointsProps {
  get_portfolio_list: () => string; // 포트폴리오 리스트 조회
  get_portfolio_details: (idPortfolio: number) => string; // 포트폴리오 상세 조회
  get_portfolio_by_tags: () => string; // 태그별 포트폴리오 조회
}

// 포트폴리오 관련 엔드포인트 정의
export const PortfolioEndpoints: PortfolioEndpointsProps = {
  get_portfolio_list: () => `api/lodong-new-web/auth/portfolio`, // 포트폴리오 리스트 조회
  get_portfolio_details: (idPortfolio: number) =>
    `api/lodong-new-web/auth/portfolio/${idPortfolio}`, // 포트폴리오 상세 조회
  get_portfolio_by_tags: () => `api/lodong-new-web/auth/portfolio/tags`, // 태그별 포트폴리오 조회
};

export default PortfolioEndpoints;
