import React, { useRef, useState } from 'react';
import FloatingButtonGroup from '../../components/user/mainPage/FloatingButton';
import TextShow from '../../components/user/mainPage/TextShow';
import KeyTechnology from '../../components/user/mainPage/KeyTechnology';
import { Introduction } from '../../components/user/mainPage/introduction';
import ContactUs from '../../components/user/mainPage/ContactUs';
import Business from '../../components/user/mainPage/Business';
import MainPortfolio from '../../components/user/mainPage/MainPortfolio';
import Footer from '../../components/Footer';
import TopBar from '../../components/user/topBar/TopBar';
import styled, { css, keyframes } from 'styled-components';

// slideDown 애니메이션 keyframes 정의
const slideDownKeyframes = keyframes`
  from {
    transform: translateY(-100px);
  }
  to {
    transform: translateY(0);
  }
`;
const slideDownKeyframesM = keyframes`
  from {
    transform: translateY(-60px);
  }
  to {
    transform: translateY(0);
  }
`;

const slideDownAnimation = css`
  animation: ${slideDownKeyframes} 0.3s ease-out forwards;
  @media (max-width: 1024px) {
    animation: ${slideDownKeyframesM} 0.3s ease-out forwards;
  }
`;

const SlideDownContainer = styled.div<{ slide: boolean }>`
  background: rgb(32, 32, 32);
  overflow: hidden;
  ${(props) => props.slide && slideDownAnimation}

  @media ( max-width: 1024px) {
    ${(props) => props.slide && slideDownKeyframesM}
  }
`;

function MainPage() {
  const [isShowPlaying, setIsShowPlaying] = useState(true);
  const [slide, setSlide] = useState(false);
  const scrollRef = useRef<HTMLDivElement>(null);

  const handleShowEnd = () => {
    // TextShow 애니메이션이 끝나면 실행됨.
    setIsShowPlaying(false);
    // 슬라이드 애니메이션을 시작 (즉, 위에서 아래로 등장)
    setSlide(true);
  };

  return (
    <div ref={scrollRef}>
      <div
        style={{
          opacity: isShowPlaying ? 0 : 1,
          transition: 'opacity 0.3s ease-out',
        }}
      >
        <FloatingButtonGroup />
      </div>

      <SlideDownContainer slide={slide}>
        <TopBar isShowPlaying={isShowPlaying} variant="dark" />
        <div
          style={{
            height: '100dvh',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <TextShow onShowEnd={handleShowEnd} />
        </div>
      </SlideDownContainer>
      <div
        style={{
          maxHeight: isShowPlaying ? 0 : '10000dvh',
          overflow: 'clip',
          transition: 'all 1s',
        }}
      >
        <KeyTechnology />
        <Introduction />
        <MainPortfolio />
        <Business />
        <ContactUs />
        <div
          style={{
            opacity: isShowPlaying ? 0 : 1,
          }}
        >
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default MainPage;
