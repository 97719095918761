import { useEffect, useMemo, useRef } from 'react';

import style from './introduction.module.css';
import { useIsMobile } from '../../../hooks/useIsMobile';
import introduction_background from '../../../assets/images/mainPage/introduction_background.png';
import Button from '../../../components/Button';
import styled from 'styled-components';

const Desc = styled.p`
  text-align: start;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px; /* 150% */

  @media (max-width: 1024px) {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 160% */
  }
`;

const Title = styled.h1`
  font-size: 48px;
  font-style: normal;
  font-weight: 800;
  line-height: 85px; /* 177.083% */
  @media (max-width: 1024px) {
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: 85px; /* 265.625% */
  }
`;

const SubMenu = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  color: #fff;
`;

const SubMenuTop = styled.div`
  display: flex;
  align-items: end;
  gap: 20px;
`;

const Number = styled.div`
  color: rgba(255, 255, 255, 0.2);
  font-family: 'Yaldevi Colombo ExtraLight';
  font-size: 96px;
  line-height: 84px;
  font-style: normal;
  font-weight: 300;
`;

const Text = styled.div`
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
`;

const SubMenuBottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 25px;
`;

const SubText = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
`;
const SubText2 = styled.div`
  white-space: pre-line;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  line-height: 20px;
`;

export function Introduction() {
  const entireViewRef = useRef<HTMLDivElement>(null);
  const thisScreenRef = useRef<HTMLDivElement>(null);

  const fromLeftRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const isIntersecting = useRef(false);

  const isMobile = useIsMobile();

  const shouldUseAnimationFallback = useMemo(() => {
    return !CSS.supports('view-timeline', '--this-screen');
  }, []);

  useEffect(() => {
    const parent = document;
    if (!parent) return;

    const handleScroll = () => {
      if (!shouldUseAnimationFallback) return;

      if (!entireViewRef.current) return;
      if (!thisScreenRef.current) return;
      if (!fromLeftRef.current) return;
      if (!containerRef.current) return;

      const top = entireViewRef.current.getBoundingClientRect().top;
      const scrollProgress = -top / window.innerHeight;

      if (isIntersecting.current) {
        if (top < window.innerHeight)
          fromLeftRef.current.style.transform = `translateX(${Math.min(
            -top,
            0
          )}px)`;

        const third = 1 / 3;
        const thisScrollProgress =
          Math.min(Math.max(0, scrollProgress - 0.3), 0.7) * (1 / 0.7);
        if (isMobile) {
          containerRef.current.childNodes.forEach((child, index) => {
            if (child instanceof HTMLElement) {
              child.style.transform = `translateX(${
                (third * index * (3 / 2) - thisScrollProgress) *
                window.innerWidth *
                3
              }px)`;
            }
          });
        } else {
          containerRef.current.childNodes.forEach((child, index) => {
            if (child instanceof HTMLElement) {
              child.style.transform = `translateY(${Math.max(
                0,
                (third * (index + 1) - thisScrollProgress) *
                  window.innerHeight *
                  2.5
              )}px)`;
            }
          });
        }
      }
    };

    parent.addEventListener('scroll', handleScroll);
    return () => {
      parent.removeEventListener('scroll', handleScroll);
    };
  }, [isMobile, shouldUseAnimationFallback]);

  useEffect(() => {
    const element = entireViewRef.current;
    if (!element) return;
    const intersectionObserver = new IntersectionObserver((entries) => {
      const entry = entries[0];
      isIntersecting.current = entry.isIntersecting;
    });
    intersectionObserver.observe(element);
    return () => {
      intersectionObserver.unobserve(element);
    };
  }, [shouldUseAnimationFallback]);

  return (
    <div
      style={{
        height: '100dvh',
        viewTimeline: '--entire-view',
        position: 'relative',
      }}
      ref={entireViewRef}
    >
      <div className={style.introductionContainer} ref={thisScreenRef}>
        <img
          src={introduction_background}
          alt="background"
          className={style.background}
        ></img>
        <div
          style={
            shouldUseAnimationFallback
              ? {}
              : {
                  animationName: style['slide-from-left'],
                  animationDuration: '0.1s',
                  animationTimeline: '--this-screen',
                }
          }
          className={style.textContainer}
          ref={fromLeftRef}
        >
          <Desc>
            실패 없는 개발, 소외 없는 사회 <br />
            어제보다 나은 오늘, 오늘보다 나은 세상
          </Desc>
          <Title>로동이 함께 걷습니다</Title>
          <Button>회사소개 바로가기</Button>
        </div>
        <div className={style.introductionItemContainerContainer}>
          <div className={style.introductionItemContainer} ref={containerRef}>
            <IntroductionItem
              number="01"
              title="신뢰"
              subtitle="고객만족과 믿음을 주는 기업"
              content={
                '로동은 고객의 신뢰를 위해 최우선으로 생각하며\n 함께 성장하는 파트너로서의 가치를 지향합니다'
              }
            />
            <IntroductionItem
              number="02"
              title="기술"
              subtitle="혁신적이고 획기적인 기술력을 가진 기업"
              content={
                '최고의 기술력과 열정을 가지고 사용자들에게\n 혁신적인 능력을 제공하려고 합니다'
              }
            />
            <IntroductionItem
              number="03"
              title="소통"
              subtitle="고객과의 소통을 최우선으로 하는 기업"
              content={
                '신뢰와 투명성을 바탕으로 국가경제의 발전과\n더 나은 사회적 가치를 위해 기여하겠습니다'
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function IntroductionItem({
  number,
  title,
  subtitle,
  content,
}: {
  number: string;
  title: string;
  subtitle: string;
  content: string;
}) {
  return (
    <SubMenu>
      <SubMenuTop>
        <Number>{number}</Number>
        <Text>{title}</Text>
      </SubMenuTop>
      <SubMenuBottom>
        <SubText>{subtitle}</SubText>
        <SubText2>{content}</SubText2>
      </SubMenuBottom>
    </SubMenu>
  );
}
