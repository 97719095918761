export const MainPageUrl = '/'; // 메인페이지 페이지
export const PortfolioPageUrl = '/portfolio'; // 포트폴리오 페이지
export const PortfolioDetailPageUrl = '/portfolio/detail'; // 포트폴리오 페이지
export const CompanyIntroductionPageUrl = '/company_introduction'; // 로동 페이지
export const CeoIntroductionPageUrl = '/ceo_introduction'; // CEO 인사말 페이지
export const HrdloServicesPageUrl = '/hrdlo-services'; // hrdlo 페이지
export const HomepageProductionPageUrl = '/homepage-production'; // hrdlo 페이지
export const ArtfleetServicesPageUrl = '/artfleet-services'; // artfleet 페이지
export const ServerHostingServicesPageUrl = '/server-hosting-services'; // server-hosting 페이지
export const ServerHostingServicesPricePageUrl =
  '/server-hosting-services/price'; // server-hosting 페이지
export const DevelopmentServicesPageUrl = '/development-services'; // hrdlo 페이지
export const DesignServicesPageUrl = '/design-services'; // hrdlo 페이지
export const MarketingServicesPageUrl = '/marketing-services'; // hrdlo 페이지
export const InquiryPageUrl = '/inquiry'; // 문의하기 페이지
export const LocationPageUrl = '/location'; // 오시는길 페이지
export const BrochurePageUrl = '/brochure'; //부로슈어 호스팅

export const AdminLoginPageUrl = '/admin'; // 오시는길 페이지
export const AdminInquiryPageUrl = '/admin/inquiry'; // 문의하기 페이지
export const AdminPortfolioPageUrl = '/admin/portfolio'; // 문의하기 페이지
export const AdminPortfolioAddPageUrl = '/admin/portfolio/add'; // 문의하기 페이지
export const AdminPortfolioUpdatePageUrl = '/admin/portfolio/update'; // 문의하기 페이지
