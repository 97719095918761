import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { theme } from './assets/css/theme';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from './assets/css/GlobalStyle';
import {
  AdminInquiryPageUrl,
  AdminLoginPageUrl,
  AdminPortfolioAddPageUrl,
  AdminPortfolioPageUrl,
  AdminPortfolioUpdatePageUrl,
  ArtfleetServicesPageUrl,
  BrochurePageUrl,
  CeoIntroductionPageUrl,
  CompanyIntroductionPageUrl,
  DesignServicesPageUrl,
  DevelopmentServicesPageUrl,
  HomepageProductionPageUrl,
  HrdloServicesPageUrl,
  InquiryPageUrl,
  LocationPageUrl,
  MainPageUrl,
  MarketingServicesPageUrl,
  PortfolioDetailPageUrl,
  PortfolioPageUrl,
  ServerHostingServicesPageUrl,
  ServerHostingServicesPricePageUrl,
} from './url/lodongUrl';

import NotFoundPage from './pages/NotFoundPage';
import MainPage from './pages/user/MainPage';
import PortfolioPage from './pages/user/portfolioPage/PortfolioPage';
import CompanyIntroductionPage from './pages/user/introductionPage/CompanyIntroductionPage';
import CEOIntroductionPage from './pages/user/introductionPage/CEOIntroductionPage';
import Footer from './components/Footer';
import LocationPage from './pages/user/LocationPage';
import HrdloServicesPage from './pages/user/servicesPage/HrdloServicesPage';
import ArtfleetServicesPage from './pages/user/servicesPage/ArtfleetServicesPage';
import ServerHostingServicesPage from './pages/user/servicesPage/ServerHostingServicesPage';
import DevelopmentServicesPage from './pages/user/servicesPage/DevelopmentServicesPage';
import DesignServicesPage from './pages/user/servicesPage/DesignServicesPage';
import MarketingServicesPage from './pages/user/servicesPage/MarketingServicesPage';
import InquiryPage from './pages/user/InquiryPage';
import ServerHostingServicesPricePage from './pages/user/servicesPage/ServerHostingServicesPricePage';
import AdminLoginPage from './pages/admin/AdminLoginPage';
import AdminInquiryPage from './pages/admin/AdminInquiryPage';
import AdminPortfolioAddPage from './pages/admin/AdminPortfolioAddPage';
import HomepageProductionPage from './pages/user/HomepageProductionPage';
import AdminPortfolioPage from './pages/admin/AdminPortfolioPage';
import AdminPortfolioUpdatePage from './pages/admin/AdminPortfolioUpdatePage';
import BrochurePage from './pages/user/BrochurePage';
import PortfolioDetailPage from './pages/user/portfolioPage/PortfolioDetailPage';

const App = () => {
  const location = useLocation();

  // 조건부로 Footer를 렌더링하지 않을 경로 설정
  const shouldShowFooter = !(
    location.pathname.includes('admin') || // URL에 'admin'이 포함된 경우
    location.pathname === MainPageUrl // MainPageUrl의 경우
  );

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Routes>
        <Route path="*" element={<NotFoundPage />} />
        <Route path={MainPageUrl} element={<MainPage />} />
        <Route path={PortfolioPageUrl} element={<PortfolioPage />} />
        <Route
          path={PortfolioDetailPageUrl}
          element={<PortfolioDetailPage />}
        />
        <Route
          path={CeoIntroductionPageUrl}
          element={<CEOIntroductionPage />}
        />
        <Route
          path={CompanyIntroductionPageUrl}
          element={<CompanyIntroductionPage />}
        />
        <Route
          path={HomepageProductionPageUrl}
          element={<HomepageProductionPage />}
        />
        <Route path={LocationPageUrl} element={<LocationPage />} />
        <Route path={InquiryPageUrl} element={<InquiryPage />} />
        <Route path={HrdloServicesPageUrl} element={<HrdloServicesPage />} />
        <Route
          path={ArtfleetServicesPageUrl}
          element={<ArtfleetServicesPage />}
        />
        <Route
          path={ServerHostingServicesPageUrl}
          element={<ServerHostingServicesPage />}
        />
        <Route
          path={ServerHostingServicesPricePageUrl}
          element={<ServerHostingServicesPricePage />}
        />
        <Route
          path={DevelopmentServicesPageUrl}
          element={<DevelopmentServicesPage />}
        />
        <Route path={DesignServicesPageUrl} element={<DesignServicesPage />} />
        <Route
          path={MarketingServicesPageUrl}
          element={<MarketingServicesPage />}
        />
        <Route path={BrochurePageUrl} element={<BrochurePage />} />
        {/* ///관리자 */}
        <Route path={AdminLoginPageUrl} element={<AdminLoginPage />} />
        <Route path={AdminInquiryPageUrl} element={<AdminInquiryPage />} />
        <Route path={AdminPortfolioPageUrl} element={<AdminPortfolioPage />} />
        <Route
          path={AdminPortfolioAddPageUrl}
          element={<AdminPortfolioAddPage />}
        />
        <Route
          path={AdminPortfolioUpdatePageUrl}
          element={<AdminPortfolioUpdatePage />}
        />
      </Routes>
      {shouldShowFooter && <Footer />}
    </ThemeProvider>
  );
};

export default App;
